import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Layout, Skeleton } from "antd";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import NoData from "./NoData";
import axios from "axios";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/500.svg";
import "./Dashboard.scss";
import { withRouter } from "react-router";
const { Content } = Layout;

const AccessContext = React.createContext({
  access: {
    all: false,
    snapshot: false,
    partners: false,
    partners_edit: false,
    email_search: false,
    global_search: false,
    catalog: false,
    catalog_edit: false,
    orders: false,
    orders_edit: false,
    shipments: false,
    shipments_edit: false,
    ndr_requests: false,
    finance: false,
    finance_edit: false,
    store_users: false,
    store_users_edit: false,
    data_export: false,
    data_export_edit: false,
    data_import: false,
    data_import_edit: false,
    disputes: false,
    disputes_edit: false,
    tats: false,
    tats_edit: false,
    flags: false,
    flags_edit: false,
    access_requests: false,
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  componentDidUpdate(pp) {
    if (this.props.location.pathname !== pp.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoData
          image={ErrorIcon}
          heading="Oops! Something went wrong!"
          content="If you continue to see this error, please contact support"
        />
      );
    }

    return this.props.children;
  }
}

class Dashboard extends Component {
  state = {
    accessLoaded: false,
    sidebarOpen: window.innerWidth > 768 ? true : false,
    geo: "in",
    access: {
      all: false,
      snapshot: false,
      partners: false,
      partners_edit: false,
      email_search: false,
      global_search: false,
      catalog: false,
      potd_catalog: false,
      ndr_requests: false,
      catalog_edit: false,
      orders: false,
      orders_edit: false,
      shipments: false,
      shipments_edit: false,
      finance: false,
      finance_edit: false,
      store_users: false,
      store_users_edit: false,
      data_export: false,
      data_export_edit: false,
      data_import: false,
      data_import_edit: false,
      disputes: false,
      disputes_edit: false,
      tats: false,
      tats_edit: false,
      flags: false,
      flags_edit: false,
      access_requests: false,
      sourcing: false,
      sourcing_edit: false,
      tiers: false,
      tiers_edit: false,
    },
  };

  getDefaultRoute() {
    const routes = {
      all: "/snapshot",
      snapshot: "/snapshot",
      partners: "/partners",
      partners_edit: "/partners",
      email_search: "/email-search",
      global_search: "/global-search",
      catalog: "/product-catalog",
      potd_catalog: "/potd-catalog",
      catalog_edit: "/product-catalog",
      orders: "/vforders",
      ndr_requests: "/ndr/open",
      orders_edit: "/vforders",
      shipments: "/shipments",
      shipments_edit: "/shipments",
      finance: "/finance/transactions",
      finance_edit: "/finance/transactions",
      store_users: "/store-users-list",
      store_users_edit: "/store-users-list",
      data_export: "/export-data",
      data_export_edit: "/export-data",
      data_import: "/import-data",
      data_import_edit: "/import-data",
      disputes: "/get-disputes",
      disputes_edit: "/get-disputes",
      tats: "/tats-report",
      tats_edit: "/tats-report",
      flags: "/flags",
      flags_edit: "/flags",
      access_requests: "/request-access-submits",
      sourcing: "/mandatory-sourcing",
      sourcing_edit: "/mandatory-sourcing",
      tiers: "/rate-cards",
      tiers_edit: "/rate-cards",
    };
    const firstRoute = Object.entries(this.state.access)
      .filter((a) => a[0] !== "export_csv")
      .find((e) => e[1]);
    if (firstRoute && firstRoute[0]) {
      return `/${this.state.geo}${routes[firstRoute[0]]}`;
    } else {
      return `/${this.state.geo}/snapshot`;
    }
  }

  getUser = () => {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_admin",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        let access = {};
        res.data.admin.access.forEach((a) => (access[a] = true));
        this.setState({
          accessLoaded: true,
          access,
          geo: res.data.admin.geo,
        });
      })
      .catch(() => {});
  };

  componentWillMount() {
    this.getUser();
    this.unlisten = this.props.history.listen((location, action) => {
      this.getUser();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  toggleSidebar(){
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  }

  render() {
    const { auth } = this.props;

    if (!auth.logged_in) return <Redirect to="/login" />;

    if (this.state.accessLoaded && !this.props.children) {
      return <Redirect to={this.getDefaultRoute()} />;
    }

    return (
      <AccessContext.Provider value={this.state.access}>
        <Layout style={{ height: "100%" }}>
          <Navbar
            user={auth.user}
            toggleSidebar={() => this.toggleSidebar()}
            toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
          />
          <Layout>
            {!this.props.hideSidebar && (
              <Sidebar
                {...this.props}
                geo={this.state.geo}
                user={auth.user}
                sidebarOpen={this.state.sidebarOpen}
                toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
                toggleAddStoreModal={() => this.toggleAddStoreModal()}
              />
            )}
            <Layout>
              <Content
                className={`dashboard-content ${
                  this.props.hideSidebar ? "no-sidebar" : ""
                }`}
              >
                <ErrorBoundary {...this.props}>
                  {this.state.accessLoaded ? (
                    this.props.children
                  ) : (
                    <Skeleton active />
                  )}
                </ErrorBoundary>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </AccessContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const WrappedDashboard = connect(mapStateToProps)(withRouter(Dashboard));
export { AccessContext, WrappedDashboard as default };
