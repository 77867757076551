import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Row } from 'antd';
import logo from '../../assets/images/logos/logo.svg';
import { connect } from 'react-redux';

import './LoginPageContainer.scss';

class LoginPageContainer extends Component {

  render() {
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/`} />
    return (
      <div className="login-page-container">
        <div className="login-container">
          <Row type="flex" justify="center">
            <img className="logo" src={logo} alt="Logo" />
          </Row>
          <Row type="flex" justify="center">
            <p className="tagline">Dropshipping made easy.</p>
          </Row>
          {this.props.children}
          <div className="login-page-footer">
            <Row type="flex" justify="center">
              <p>copyright &copy; {new Date().getFullYear()} vFulfill</p>
            </Row>
            <Row type="flex" justify="center">
              <ul className="footer-links">
                <li><Link to="/terms-conditions">Terms</Link></li>
                <li><Link to="/privacy-policy">Privacy</Link></li>
                <li><Link to="/help">Help</Link></li>
              </ul>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps)(LoginPageContainer);
