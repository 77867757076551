import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { ReactComponent as ReportingIcon } from "../../../assets/images/icons/reporting.svg";
import { Layout, Menu, Icon } from "antd";
import { AccessContext } from "../../Dashboard";
import "./Sidebar.scss";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Sidebar extends Component {
  state = {
    openKeys: [],
  };
  render() {
    const props = this.props;
    const geo = props.geo;

    return (
      <AccessContext.Consumer>
        {(access) => {
          return (
            <Sider
              className={`sidebar ${
                props.sidebarOpen ? "sidebar-open" : "sidebar-closed"
              }`}
              style={{ background: "rgb(230, 235, 241)" }}
              breakpoint="md"
              collapsed={!props.sidebarOpen}
              collapsedWidth={0}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                openKeys={this.state.openKeys}
                onOpenChange={(keys) =>
                  this.setState({ openKeys: [keys[keys.length - 1]] })
                }
                style={{ height: "auto", borderRight: 0, fontSize: "12px" }}
              >
                <Menu.Item key="snapshot">
                  <Link to={`/${geo}/snapshot`}>
                    <Icon type="dashboard" />
                    Snapshot
                  </Link>
                </Menu.Item>

                <SubMenu
                  key="storeUsers"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/store-users-list`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="user" />
                      <span>Partners</span>
                    </div>
                  }
                >
                  <Menu.Item key="store-user-list">
                    <Link to={`/${geo}/store-users-list`}>Partners List</Link>
                  </Menu.Item>
                  
                  <Menu.Item key="quotation-request">
                    <Link to={`/${geo}/quotation-request`}>Quotation Request</Link>
                  </Menu.Item>
     
                  <Menu.Item key="sourcing-requests">
                    <Link to={`/${geo}/sourcing-requests`}>
                      Sourcing Requests
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="catalog-requests">
                    <Link to={`/${geo}/catalog-requests`}>
                      Catalog Requests
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="service-requests">
                    <Link to={`/${geo}/service-requests`}>
                      Service Requests
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="request-access-listing">
                    <Link to={`/${geo}/request-access-submits`}>Signup</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="product-catalog"
                  onTitleClick={() =>
                    props.history.push(
                      access.all || access.catalog || access.catalog_edit
                        ? `/${geo}/product-catalog`
                        : `/${geo}/potd-catalog`
                    )
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Catalog</span>
                    </div>
                  }
                >
                  <Menu.Item key="product-catalog">
                    <Link to={`/${geo}/product-catalog`}>
                      <Icon type="shopping-cart" />
                      vF Product Catalog
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="potd-catalog">
                    <a href="#" target="_blank">
                      <Icon type="shopping-cart" />
                      Product Research
                    </a>
                  </Menu.Item>
                  <Menu.Item key="ali-product-catalog">
                    <Link to={`/${geo}/ali-product-catalog`}>
                      <Icon type="shopping-cart" />
                      To be Catalogged
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="vf-media">
                    <Link to={`/${geo}/vf-media`}>
                      <Icon type="shopping-cart" />
                      VF Media
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="">
                    <Link to={`/${geo}/potd-catalog`}>
                      <Icon type="shopping-cart" />
                      POTD Nominee
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="quotations-supplier">
                    <Link to={`/${geo}/quotations-supplier`}>
                      <Icon type="shopping-cart" />
                      Supplier Quotations
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="custom-pricing">
                    <Link to={`/${geo}/custom-pricing`}>
                      <Icon type="shopping-cart" />
                      Pricing Calculator
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="pricing-updates">
                    <Link to={`/${geo}/pricing-updates`}>
                      {" "}
                      <Icon type="shopping-cart" />
                      Pricing Updates
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="label-listing">
                    <Link to={`/${geo}/label-listing`}>
                      {" "}
                      <Icon type="shopping-cart" />
                      Label Listing
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="products-drop">
                    <Link to={`/${geo}/products-drop`}>
                      {" "}
                      <Icon type="shopping-cart" />
                      Drop Products
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="sourcing"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/sourcing-summary`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="user" />
                      <span>Sourcing</span>
                    </div>
                  }
                >
                  <Menu.Item key="sourcing-summary">
                    <Link to={`/${geo}/sourcing-summary`}>Summary</Link>
                  </Menu.Item>
                  <Menu.Item key="sourcing-quotation-request">
                    <Link to={`/${geo}/sourcing-quotation-request`}>Quotation Request</Link>
                  </Menu.Item>

                  <Menu.Item key="mandatory-sourcing">
                    <Link to={`/${geo}/mandatory-sourcing`}>
                      Approved Sourcing
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="Purchase-ordersNe">
                    <a href="#" target="_blank">
                      Pending Order Sourcing
                    </a>
                  </Menu.Item> */}

                  <Menu.Item key="Purchase-ordersNew">
                    <Link to={`/${geo}/purchase-orders`}>Purchase Orders</Link>
                  </Menu.Item>

                  <Menu.Item key="Pending-Order-Sourcing">
                    <Link to={`/${geo}/pending-orders-sourcing`}>
                      Pending Order Sourcing
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="Pending-Order-Inventory">
                    <Link to={`/${geo}/pending-orders-inventory`}>
                      Pending Order Inventory
                    </Link>
                  </Menu.Item>

                  {/* <Menu.Item key="sourcing-optimization">
                    <Link to={`/${geo}/sourcing-optimization`}>
                      Sourcing Optimization
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="suggested-sourcing">
                    <Link to={`/${geo}/suggested-sourcing`}>
                      Suggested Sourcing
                    </Link>
                  </Menu.Item> */}
                </SubMenu>

                <SubMenu
                  key="Freight Planing "
                  onTitleClick={() =>
                    props.history.push(`/${geo}/freight-orders`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="user" />
                      <span> Freight Planning </span>
                    </div>
                  }
                >
                  <Menu.Item key="freight-orders">
                    <Link to={`/${geo}/freight-orders`}>
                      Create Freight Orders
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="AllFreightOrders">
                    <Link to={`/${geo}/AllFreightOrders`}>
                      All Freight Orders
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="logistics"
                  onTitleClick={() => props.history.push(`/${geo}/shipments`)}
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Logistics</span>
                    </div>
                  }
                >
                  <Menu.Item key="shipments">
                    <Link to={`/${geo}/shipments`}>
                      <Icon type="shopping-cart" />
                      Shipments
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="virtual-awbs">
                    <Link to={`/${geo}/virtual_awbs`}>
                      <Icon type="shopping-cart" />
                      Virtual AWBs
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="lost_damaged_claim">
                    <Link to={`/${geo}/lost_damaged_claim`}>
                      <Icon type="shopping-cart" />
                      Lost/Damaged Claims
                    </Link>
                  </Menu.Item>
                 
                </SubMenu>

                <SubMenu
                  key="ndr"
                  onTitleClick={() => props.history.push(`/${geo}/ndr/open`)}
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>NDR</span>
                    </div>
                  }
                >
                  <Menu.Item key="ndr-open">
                    <Link to={`/${geo}/ndr/open`}>Open NDR </Link>
                  </Menu.Item>
                  <Menu.Item key="ndr-responded">
                    <Link to={`/${geo}/ndr/responded`}>Responded NDR </Link>
                  </Menu.Item>
                  <Menu.Item key="ndr-expired">
                    <Link to={`/${geo}/ndr/expired`}>Expired NDR </Link>
                  </Menu.Item>
                  <Menu.Item key="ndr-all">
                    <Link to={`/${geo}/ndr/all`}> All NDR</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="finance"
                  onTitleClick={() =>
                    props.history.push(`/in/finance/transactions`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Finance</span>
                    </div>
                  }
                >
                  <Menu.Item key="transactions">
                    <Link to="/in/finance/transactions">Transactions</Link>
                  </Menu.Item>
                  <Menu.Item key="withdrawal-requests">
                    <Link to="/in/finance/withdrawal-requests">
                      Withdrawal Requests
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="wire-transaction-list">
                    <Link to="/in/finance/wire-transaction-list">
                      Wire Transaction List
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="download-invoice">
                    <Link to={`/${geo}/finance/download-invoice`}>
                      Download Invoice
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="partner-invoice-list">
                    <Link to="/in/finance/partner-invoice-list">
                      Partner Invoice
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="store-user-kyc">
                    <Link to={`/${geo}/store-users-kyc`}>Store Users KYC</Link>
                  </Menu.Item>
                  <Menu.Item key="global_transactions">
                    <Link to="/global/finance/global_transactions">
                      Global Transactions
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {/* <SubMenu
                  key="global_finance"
                  onTitleClick={() =>
                    props.history.push(`/global/finance/global_transactions`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>International Finance</span>
                    </div>
                  }
                >
                  <Menu.Item key="transactions">
                    <Link to="/global/finance/global_transactions">
                      Global Transactions
                    </Link>
                  </Menu.Item>
                </SubMenu> */}

                <Menu.Item key="partners">
                  <Link to={`/${geo}/partners`}>
                    <Icon type="user" />
                    Enablers
                  </Link>
                </Menu.Item>

                {access.admins && (
                  <Menu.Item key="admins">
                    <Link to={`/${geo}/admins`}>
                      <Icon type="user" />
                      Admins
                    </Link>
                  </Menu.Item>
                )}

                <SubMenu
                  key="global_finance"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/email-search`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Tools</span>
                    </div>
                  }
                >
                  <Menu.Item key="email-search">
                    <Link to={`/${geo}/email-search`}>
                      <Icon component={ReportingIcon} />
                      Email Search
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="global-serch">
                    <Link to={`/${geo}/global-search`}>
                      <Icon type="shopping-cart" />
                      Global Search
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="trending-orders">
                    <Link to={`/${geo}/trending-orders`}>
                      <Icon type="shopping-cart" />
                      Trending Orders
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="import-data">
                    <Link to={`/${geo}/import-data`}>
                      <Icon type="shopping-cart" />
                      Data Import
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="vendors-order"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/vendor-open-order`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>VF Orders</span>
                    </div>
                  }
                >
                  <Menu.Item key="vendor-open-order">
                    <Link to={`/${geo}/vendor-open-order`}>vF Open Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="/vendor-all-order">
                    <Link to={`/${geo}/vendor-all-order`}>vF All Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="/hold-orders">
                    <Link to={`/${geo}/hold-orders`}>Hold Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="/failed-wms-orders">
                    <Link to={`/${geo}/failed-wms-orders`}>
                      Failed WMS Orders
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="shopify-orders"
                  onTitleClick={() => props.history.push(`/${geo}/open-orders`)}
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Shopify Orders</span>
                    </div>
                  }
                >
                  <Menu.Item key="shopify-open-orders">
                    <Link to={`/${geo}/open-orders`}>Open Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="shopify-all-orders">
                    <Link to={`/${geo}/orders`}>All Orders</Link>
                  </Menu.Item>
                </SubMenu>

                {(access.all ||
                  (geo === "global" &&
                    (access.orders || access.orders_edit))) && (
                  <SubMenu
                    key="global-order"
                    onTitleClick={() =>
                      props.history.push(`/global/global-open-order`)
                    }
                    title={
                      <div>
                        <Icon style={{ opacity: 0.6 }} type="shop" />
                        <span>International Orders</span>
                      </div>
                    }
                  >
                    <Menu.Item key="global-open-order">
                      <Link to={`/global/global-open-order`}>Open Orders</Link>
                    </Menu.Item>
                    <Menu.Item key="global-all-order">
                      <Link to={`/global/global-all-order`}>All Orders</Link>
                    </Menu.Item>
                  </SubMenu>
                )}

                <SubMenu
                  key="tiers"
                  onTitleClick={() => props.history.push(`/${geo}/tiers`)}
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="user" />
                      <span>Tiers & Rate Cards</span>
                    </div>
                  }
                >
                  <Menu.Item key="tiers">
                    <Link to={`/${geo}/tiers`}>Tiers</Link>
                  </Menu.Item>
                  <Menu.Item key="rate-cards">
                    <Link to={`/${geo}/rate-cards`}>Rate Cards</Link>
                  </Menu.Item>
                  <Menu.Item key="rate-card-groups">
                    <Link to={`/${geo}/rate-card-groups`}>
                      Rate Card Groups
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {/* <SubMenu
                  key="inventory"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/inventory/vf-inventory`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span>Inventory Listing</span>
                    </div>
                  }
                >
                  
                </SubMenu> */}

                <Menu.Item key="vf-inventory">
                  <Link to={`/${geo}/inventory/vf-inventory`}>
                    <Icon style={{ opacity: 0.6 }} type="shop" />
                    Inventory
                  </Link>
                </Menu.Item>

                <SubMenu
                  key=" Escalations"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/partner-escalations`)
                  }
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span> Escalations</span>
                    </div>
                  }
                >
                  <Menu.Item key="get-disputes">
                    <Link to={`/${geo}/partner-escalations`}>
                      <Icon component={ReportingIcon} />
                      Partner Escalations
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="shipment-request">
                    <Link to={`/${geo}/shipment-request`}>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      Requests
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ndr-escalation-report">
                    <Link to={`/${geo}/ndr-escalation-report`}>
                      <Icon type="shopping-cart" />
                      NDR Escalation
                    </Link>
                  </Menu.Item>
                
                </SubMenu>

                <SubMenu
                  key="bigquery-reports"
                  onTitleClick={() =>
                    props.history.push(`/${geo}/partners-report`)
                  }
                  title={
                    <div>
                      <Icon component={ReportingIcon} />
                      <span>Bigquery Reports</span>
                    </div>
                  }
                >
                  <Menu.Item key="partners-report">
                    <Link to={`/${geo}/partners-report`}> Partner</Link>
                  </Menu.Item>
                  <Menu.Item key="orders-report">
                    <Link to={`/${geo}/orders-report`}> Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="shipments-report">
                    <Link to={`/${geo}/shipments-report`}>Shipment</Link>
                  </Menu.Item>
                  <Menu.Item key="smart-inventory-report">
                    <Link to={`/${geo}/smart-inventory-report`}>
                      Inventory Suggestion
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="transactions-report">
                    <Link to={`/${geo}/transactions-report`}>
                      Transactions Report
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="Broadcasts"
                  onTitleClick={() => props.history.push(`/${geo}/broadcast`)}
                  title={
                    <div>
                      <Icon style={{ opacity: 0.6 }} type="shop" />
                      <span> Broadcasts</span>
                    </div>
                  }
                >
                  <Menu.Item
                  // key="get-disputes"
                  >
                    <Link to={`/${geo}/broadcast`}>Create Broadcast</Link>
                  </Menu.Item>

                  <Menu.Item
                  // key="get-disputes"
                  >
                    <Link to={`/${geo}/all-broadcast`}>All Broadcast</Link>
                  </Menu.Item>

                  <Menu.Item
                  // key="get-disputes"
                  >
                    <Link to={`/${geo}/email-broadcast`}>
                      Emailer Broadcast
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                  // key="get-disputes"
                  >
                    <Link to={`/${geo}/emailer-listing`}>Emailer Listing</Link>
                  </Menu.Item>
                </SubMenu>

                {/* {(access.all || access.orders || access.orders_edit) && (
                  <SubMenu
                    key="shopify-orders"
                    onTitleClick={() =>
                      props.history.push(`/${geo}/open-orders`)
                    }
                    title={
                      <div>
                        <Icon style={{ opacity: 0.6 }} type="shop" />
                        <span>Shopify Orders</span>
                      </div>
                    }
                  >
                    <Menu.Item key="shopify-open-orders">
                      <Link to={`/${geo}/open-orders`}>Open Orders</Link>
                    </Menu.Item>
                    <Menu.Item key="shopify-all-orders">
                      <Link to={`/${geo}/orders`}>All Orders</Link>
                    </Menu.Item>
                  </SubMenu>
                )} */}

                {/* {(access.all || access.finance) && (
                  <Menu.Item key="withdrawal-requests">
                    <Link to="/finance/withdrawal-requests">
                      <Icon type="shop" />
                      Withdrawal Requests
                    </Link>
                  </Menu.Item>
                )} */}
                {/* {access.all && (
                  <Menu.Item key="lbh-sync-product">
                    <Link to={`/${geo}/lbh-sync-product`}>
                      <Icon type="shopping-cart" />
                      LBH Sync Product
                    </Link>
                  </Menu.Item>
                )} */}

                {/* {(access.all ||
                  access.data_export ||
                  access.data_export_edit) && (
                  <SubMenu
                    key="data_export"
                    onTitleClick={() => props.history.push(`/${geo}/logs`)}
                    title={
                      <div>
                        <Icon style={{ opacity: 0.6 }} type="shop" />
                        <span>Data Export</span>
                      </div>
                    }
                  >
                    <Menu.Item key="logs">
                      <Link to={`/${geo}/logs`}>Logs</Link>
                    </Menu.Item>
                    {(access.all || access.data_export_edit) && (
                      <Menu.Item key="/export-data">
                        <Link to={`/${geo}/export-data`}>Export Data</Link>
                      </Menu.Item>
                    )}
                  </SubMenu>
                )} */}

                {/* {(access.all || access.orders) && (
                  <Menu.Item key="order-serch">
                    <Link to={`/${geo}/order-search`}>
                      <Icon type="shopping-cart" />
                      Order Search
                    </Link>
                  </Menu.Item>
                )} */}
                {/* {(access.all ||
                  access.data_import ||
                  access.data_import_edit) && (
                  <Menu.Item key="import-data">
                    <Link to={`/${geo}/import-data`}>
                      <Icon type="shopping-cart" />
                      Data Import
                    </Link>
                  </Menu.Item>
                )}

                {(access.all || access.reporting) && (
                  <Menu.Item key="reporting">
                    <Link to={`/${geo}/reporting`}>
                      <Icon component={ReportingIcon} /> Reporting
                    </Link>
                  </Menu.Item>
                )} */}

                {/* {(access.all || access.jobs) && (
                  <Menu.Item key="reporting-jobs">
                    <Link to={`/${geo}/reporting-jobs`}>
                      <Icon component={ReportingIcon} />
                      Reporting Jobs
                    </Link>
                  </Menu.Item>
                )}

                {(access.all || access.jobs) && (
                  <Menu.Item key="custom-chalans">
                    <Link to={`/${geo}/custom-chalans`}>
                      <Icon component={ReportingIcon} />
                      Custom Chalans
                    </Link>
                  </Menu.Item>
                )}

                {(access.all || access.shipments) && (
                  <Menu.Item key="awb-data">
                    <Link to={`/${geo}/awb-data`}>
                      <Icon component={ReportingIcon} />
                      AWB Data
                    </Link>
                  </Menu.Item>
                )} */}

                {/* {(access.all || access.tats || access.tats_edit) && (
                  <Menu.Item key="tat-report">
                    <Link to={`/${geo}/tat-report`}>
                      <Icon component={ReportingIcon} /> TAT Report
                    </Link>
                  </Menu.Item>
                )} */}
                {/* {(access.all || access.tats || access.tats_edit) && (
                  <Menu.Item key="tats-report">
                    <Link to={`/${geo}/tats-report`}>
                      <Icon component={ReportingIcon} /> TAT Report New
                    </Link>
                  </Menu.Item>
                )}

                {(access.all || access.flags || access.flags_edit) && (
                  <Menu.Item key="flags">
                    <Link to={`/${geo}/flags`}>
                      <Icon component={ReportingIcon} /> Flags
                    </Link>
                  </Menu.Item>
                )} */}
              </Menu>

              <Menu
                className="show-on-small-screen"
                mode="inline"
                defaultSelectedKeys={["help"]}
                defaultOpenKeys={["help"]}
                style={{
                  borderRight: "0px",
                  position: "sticky",
                  top: "100%",
                  fontSize: "12px",
                }}
              >
                <SubMenu
                  key="user"
                  title={
                    <p style={{ lineHeight: "14px", margin: "6px 0" }}>
                      {props.user.user_fname}
                      <br />
                      {props.store && props.store.store_name
                        ? props.store.store_name
                        : props.user.user_fname.split(" ")[0] + "'s Store"}
                    </p>
                  }
                >
                  <Menu.Item key="user_logout">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        props.signOut();
                      }}
                    >
                      <Icon type="poweroff" /> Logout
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
          );
        }}
      </AccessContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
