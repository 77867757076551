import React, { Component } from "react";
import { Layout, Menu, Icon, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import logo from "../../../assets/images/logos/logo_white.svg";
import "./Navbar.scss";
const { Header } = Layout;

class Navbar extends Component {
  state = {
    userDropdownVisible: false,
  };

  dropdownToggle(key, visible) {
    this.setState({
      [key]: visible,
    });
  }

  render() {
    const userDropdownMenu = (
      <Menu>
        <Menu.Item key="3">
          <Link to="/profile">
            <Icon type="user" /> Profile
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <a
            href="/logout"
            onClick={(e) => {
              e.preventDefault();
              this.props.signOut();
            }}
          >
            <Icon type="poweroff" /> Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Header className="header">
        <div className="logo">
          <Link to="/in/snapshot">
            <img src={logo} alt="vFulfil" />
          </Link>
        </div>

        <div className="navbar-ham">
          <Icon type="bars" onClick={() => this.props.toggleSidebar()} />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item className="navbar-user-detail" key="3">
            <Dropdown
              overlay={userDropdownMenu}
              trigger={["click"]}
              onVisibleChange={(visible) =>
                this.dropdownToggle("userDropdownVisible", visible)
              }
            >
              <a className="ant-dropdown-link" href="#/">
                <Avatar
                  style={{
                    backgroundColor: "#FC4747",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {this.props.user.user_fname[0]}
                </Avatar>

                <div className="avatar-details">
                  {this.props.user.user_fname}
                  <br />
                </div>

                <Icon
                  type={this.state.userDropdownVisible ? "up" : "down"}
                  style={{ margin: 0 }}
                />
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
